const MiniIconGreen = (props) => {
  const { size = "mini" } = props;

  if (size === "mini") {
    return (
      <svg
        width="14"
        height="21"
        viewBox="0 0 14 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.4087 11.3922C5.9621 10.6782 5.33418 10.0881 4.58535 9.67871C3.83653 9.2693 2.99202 9.05436 2.13311 9.05457C1.92056 9.05434 1.7082 9.06695 1.49725 9.09232C1.36275 9.10975 1.23144 9.13091 1.09992 9.15808C0.400326 11.0493 1.01094e-05 12.8204 1.01094e-05 14.1836C-0.00196219 15.3121 0.284684 16.4232 0.834109 17.4169C1.89282 15.1793 3.55299 13.2616 5.63919 11.8664C5.89179 11.7004 6.14374 11.5411 6.40657 11.392"
            fill="#007C27"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8589 12.6343C13.7252 11.8616 13.5401 11.0983 13.3047 10.349C10.7414 10.3924 8.24758 11.1669 6.13224 12.5763C4.01689 13.9857 2.37301 15.9682 1.4043 18.278C1.60226 18.5363 1.81919 18.7802 2.05337 19.008C2.42166 19.3669 2.82965 19.685 3.26988 19.9566C3.95474 17.8324 5.3179 15.976 7.16123 14.6575C9.00456 13.3389 11.232 12.6267 13.5197 12.6245C13.6193 12.6245 13.7329 12.6245 13.8572 12.632"
            fill="#007C27"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.8095 15.7709C13.937 15.2504 14.0014 14.7172 14.0014 14.1822C14.0014 13.9604 13.9914 13.7283 13.9694 13.4899H13.9304C13.817 13.4862 13.6817 13.4825 13.5222 13.4825C11.3908 13.4834 9.31689 14.1552 7.61017 15.3976C5.90345 16.64 4.65551 18.3863 4.05273 20.3758C4.76791 20.6971 5.5334 20.8994 6.31736 20.9742C6.78456 19.4684 7.7374 18.1489 9.03494 17.2109C10.3325 16.2729 11.9058 15.7662 13.5222 15.7657C13.6038 15.7657 13.6993 15.7657 13.8098 15.7726"
            fill="#298E46"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.56054 10.1652V0.000823975C4.9015 1.80054 3.39808 4.12299 2.25148 6.47511C1.96457 7.06589 1.69726 7.6575 1.45703 8.24226C1.68211 8.21761 1.90839 8.20473 2.13488 8.20368C2.97372 8.20428 3.80269 8.37967 4.56606 8.71804C5.32943 9.05642 6.0095 9.54993 6.56054 10.1654"
            fill="#007C27"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4511 5.88556L7.4375 8.14454V10.8491C9.18073 10.0244 11.0828 9.56588 13.02 9.50325C12.6552 8.47172 12.2301 7.46137 11.7466 6.47717C11.6502 6.27969 11.5507 6.08304 11.4511 5.88867"
            fill="#2E934E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.22461 21C8.60374 20.9569 9.93898 20.5179 11.0624 19.7383C12.1858 18.9586 13.0473 17.8731 13.5384 16.6183H13.5205C12.0389 16.6143 10.5989 17.0955 9.43232 17.9845C8.41183 18.7604 7.64487 19.808 7.22461 21Z"
            fill="#2E934E"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.82046 3.08665L9.787 3.03832C9.77229 3.01571 9.75822 2.99226 9.74266 2.97028L9.25516 2.26292L9.24748 2.25234V2.24778L9.16222 2.12664C8.63277 1.38445 8.05745 0.674261 7.43945 0V7.15883L11.0513 5.12907C10.6695 4.43395 10.2574 3.74775 9.82046 3.08665Z"
            fill="#2E934E"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="14" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
  if (size === "medium") {
    return (
      <svg
        width="29"
        height="45"
        viewBox="0 0 29 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4677 24.42C12.5708 22.9482 11.31 21.7317 9.80652 20.8874C8.30305 20.0431 6.60747 19.5994 4.88276 19.5989C4.45639 19.5985 4.03041 19.6248 3.60732 19.6775C3.33616 19.7126 3.0717 19.7561 2.80891 19.8129C1.40458 23.7143 0.597803 27.3665 0.597803 30.1776C0.59348 32.5049 1.16869 34.7967 2.27161 36.8465C4.39844 32.231 7.73421 28.2755 11.9262 25.3983C12.4283 25.0638 12.9305 24.7294 13.4661 24.42"
          fill="#007C27"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.435 26.9821C28.1675 25.3876 27.7957 23.8123 27.3219 22.2664C22.1709 22.3558 17.1595 23.9532 12.9084 26.8606C8.65738 29.768 5.3536 33.8576 3.40643 38.6227C3.80483 39.1551 4.24116 39.6581 4.71201 40.1277C5.45407 40.8704 6.2765 41.5283 7.16414 42.0893C8.54056 37.7092 11.2787 33.8815 14.981 31.1621C18.6833 28.4427 23.1569 26.9732 27.752 26.9671C27.9512 26.9671 28.1805 26.9671 28.4299 26.9821"
          fill="#007C27"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3345 33.4519C28.5899 32.3792 28.7191 31.2803 28.7195 30.1777C28.7195 29.7211 28.6977 29.2429 28.6559 28.7512L28.5772 28.7429C28.3496 28.7429 28.0751 28.7295 27.7571 28.7295C23.4754 28.7301 19.309 30.1148 15.8802 32.6768C12.4515 35.2388 9.94461 38.8405 8.73419 42.9437C10.1709 43.6065 11.7087 44.0237 13.2836 44.1778C14.2218 41.0719 16.1358 38.3503 18.7424 36.4156C21.349 34.4808 24.5098 33.4359 27.7571 33.4352C27.9244 33.4352 28.1136 33.4352 28.3345 33.4503"
          fill="#298E46"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7741 21.8882V0.924805C10.4382 4.63387 7.42198 9.42656 5.11714 14.2778C4.53968 15.4969 4.00573 16.7176 3.52032 17.9233C3.97219 17.8727 4.42645 17.8465 4.88113 17.8447C7.0686 17.8428 9.21327 18.4503 11.0742 19.5989C12.0858 20.2207 12.9967 20.9926 13.7758 21.8882"
          fill="#007C27"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.601 13.0654L15.5349 17.7227V23.3013C19.037 21.6011 22.8579 20.6559 26.7494 20.527C26.0194 18.397 25.1677 16.3105 24.1986 14.2778C24.0061 13.8715 23.8069 13.4651 23.6077 13.0654"
          fill="#2E934E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.108 44.2363C17.878 44.1477 20.5599 43.2433 22.817 41.6367C25.074 40.03 26.8055 37.7928 27.7939 35.2061H27.757C24.7803 35.1979 21.8874 36.1904 19.5436 38.0239C17.494 39.6217 15.953 41.7799 15.108 44.2363Z"
          fill="#2E934E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3237 7.29276L20.2568 7.19243L20.1681 7.05196L19.1939 5.5904L19.1788 5.56866V5.55863L19.0115 5.30946C17.944 3.77834 16.7835 2.31404 15.5366 0.924805V15.6908L22.7943 11.5102C22.0226 10.0687 21.1991 8.65398 20.3237 7.29276Z"
          fill="#2E934E"
        />
      </svg>
    );
  } else {
    return null;
  }
};

export default MiniIconGreen;

import parse, { domToReact } from "html-react-parser";
import Link from "next/link";
import Image from "next/image";

const options = {
  replace: (domNode) => {
    if (!domNode.attribs) return;
    // Link
    if (domNode.attribs && domNode.name === "a") {
      // If the link is in the some kind of Tag such as <strong></strong> get the children of the tag.
      if (domNode.children[0].type === "tag") {
        return (
          <Link href={domNode.attribs.href}>
            <a className="underline transition-colors duration-100 ease-out text-secondaryDark underline-offset-4 hover:text-primaryLight">
              {domNode.children[0]?.children[0]?.data ||
                domNode.children[0]?.children[0]?.children[0]?.data ||
                domNode.attribs.href.slice(0, 50).toString() + "..."}
            </a>
          </Link>
        );
      }
      // If there is no tag directly return the children.
      return (
        <Link href={domNode.attribs.href}>
          <a className="underline transition-colors duration-100 ease-out text-secondaryDark underline-offset-4 hover:text-primaryLight">
            {domNode.children[0]?.data}
          </a>
        </Link>
      );
    }
    // Image
    if (domNode.attribs && domNode.name === "img") {
      let width;
      let height;
      // If there are width and height attributes, use them
      if (domNode.attribs.width && domNode.attribs.height) {
        width = domNode.attribs.width;
        height = domNode.attribs.height;
      }
      // If width and height are not set, get them from url
      else {
        const params = new URLSearchParams(domNode.attribs.src.split("?")[1]);
        width = params.get("width");
        height = params.get("height");
      }

      return (
        <div className="mt-6 mb-6 bg-zinc-200 w-full min-h-[300px] rounded-md">
          <Image
            className="h-auto overflow-hidden rounded-md"
            src={domNode.attribs.src}
            alt={domNode.attribs.alt}
            layout="responsive"
            width={width >= 730 ? 730 : width}
            height={
              width >= 730
                ? (Number(height) * 730) / Number(width)
                : Number(height)
            }
            placeholder="blur"
            blurDataURL={
              domNode.attribs.src.includes("?")
                ? domNode.attribs.src.split("?")[0] + "?key=blurry-thumbnail"
                : domNode.attribs.src + "?key=blurry-thumbnail"
            }
            objectFit="cover"
            objectPosition="center"
          />
        </div>
      );
    }
    // Iframe
    if (domNode.attribs && domNode.name === "iframe") {
      return (
        <div className="video-wrapper aspect-h-9 aspect-w-16">
          <iframe
            src={domNode.attribs.src}
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    // Table
    if (domNode.attribs && domNode.name === "table") {
      return (
        <div className="w-full max-w-full overflow-scroll text-left border border-gray-800 tg-wrap">
          <table className="w-full tg-table">
            {domToReact(domNode.children, options)}
          </table>
        </div>
      );
    }
    // H2
    if (domNode.attribs && domNode.name === "h2") {
      if (
        domNode.children[0].type === "tag" &&
        domNode.children[0].name === "strong"
      ) {
        return <h2>{domNode.children[0].children[0].data}</h2>;
      } else return <h2>{domNode.children[0].data}</h2>;
    }
    // H3
    if (domNode.attribs && domNode.name === "h3") {
      if (
        domNode.children[0].type === "tag" &&
        domNode.children[0].name === "strong"
      ) {
        return <h3>{domNode.children[0].children[0].data}</h3>;
      } else return <h3>{domNode.children[0].data}</h3>;
    }
    // H4
    if (domNode.attribs && domNode.name === "h4") {
      if (
        domNode.children[0].type === "tag" &&
        domNode.children[0].name === "strong"
      ) {
        return <h4>{domNode.children[0].children[0].data}</h4>;
      } else return <h4>{domNode.children[0].data}</h4>;
    }
    // H5
    if (domNode.attribs && domNode.name === "h5") {
      if (
        domNode.children[0].type === "tag" &&
        domNode.children[0].name === "strong"
      ) {
        return <h5>{domNode.children[0].children[0].data}</h5>;
      } else return <h5>{domNode.children[0].data}</h5>;
    }
    // H6
    if (domNode.attribs && domNode.name === "h6") {
      if (
        domNode.children[0].type === "tag" &&
        domNode.children[0].name === "strong"
      ) {
        return <h6>{domNode.children[0].children[0].data}</h6>;
      } else return <h6>{domNode.children[0].data}</h6>;
    }
  },
};

const getParsedHtml = (content) => {
  if (content) {
    return parse(content, options);
  } else {
    return null;
  }
};

export default getParsedHtml;

/* eslint-disable @next/next/no-img-element */
import axios from "axios";
import Link from "next/link";
import { useEffect, useState } from "react";

const LinkPreview = ({ url, text }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [link, setLink] = useState(null);
  const linkPreviewGetter = async (url) => {
    try {
      const res = await axios.get(
        `https://hortiturkey-link-preview.herokuapp.com?url=${url}`
      );
      const data = await res.data.metadata;
      if (
        (data.og.title || data.meta.title) &&
        (data.og.description || data.meta.description) &&
        (data.og.image || data.images[0].src)
      ) {
        setLink({
          title: data.og.title || data.meta.title,
          description: data.og.description || data.meta.description,
          image: data.og.image || data.images[0].src,
        });
        setError(false);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    linkPreviewGetter(url);
  }, [url]);

  if (!loading && error) {
    return (
      <Link href={url}>
        <a className="underline break-all" target="_blank">
          {text || url}
        </a>
      </Link>
    );
  } else if (!loading && !error && link) {
    return (
      <div className="my-2">
        {link.image && (
          <div className="relative h-48 min-w-full">
            <img
              className="absolute object-cover object-center w-full h-full max-w-full overflow-hidden rounded-md"
              alt={link.title}
              src={link.image}
            />
          </div>
        )}

        <h3 className="mt-2 font-medium">{link.title}</h3>
        <p className="mt-1 text-xs">{link.description}</p>
        <button className="w-full max-w-full px-3 py-2 mt-2 rounded-md bg-primaryLight text-neutral-800">
          <Link href={url}>
            <a className="max-w-full" target="_blank">
              Bağlantıya Git
            </a>
          </Link>
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col gap-2">
        <div className="w-40 h-20 rounded-md bg-neutral-100 animate-pulse"></div>
        <div className="w-full h-4 rounded-md bg-neutral-300 animate-pulse"></div>
        <div className="w-20 h-4 rounded-md bg-neutral-200 animate-pulse"></div>
      </div>
    );
  }
};

export default LinkPreview;
